import { useTheme } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWindowSize } from '@Core/useWindowSize';
import { BreakpointsPalette } from '@UX/themes/types';

type BreakPoint = {
  isMobile: () => boolean;
  isTablet: () => boolean;
  isDesktop: () => boolean;
};

const comparator = (a: number, b: number) => () => {
  if (typeof window === 'undefined') {
    throw new Error('Do not use useBreakpoint on SSR!');
  }

  return a === b;
};

const getBreakpointIndex = (breakpoints: BreakpointsPalette, width: number) => {
  if (typeof window === 'undefined') {
    return 0;
  }

  return breakpoints.reduce((acc, point) => (parseInt(point, 10) <= width ? acc + 1 : acc), 0);
};

export const useBreakpoint = (): BreakPoint => {
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  const [breakpointIndex, setBreakpointIndex] = useState(getBreakpointIndex(breakpoints, width));

  useEffect(() => {
    const newBreakpointIndex = getBreakpointIndex(breakpoints, width);

    if (newBreakpointIndex !== breakpointIndex) {
      setBreakpointIndex(newBreakpointIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, breakpointIndex]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isMobile = useCallback(comparator(breakpointIndex, 0), [breakpointIndex]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isTablet = useCallback(comparator(breakpointIndex, 1), [breakpointIndex]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isDesktop = useCallback(comparator(breakpointIndex, 2), [breakpointIndex]);

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breakpointIndex],
  );
};
